"use client";

import { Price, useI10n } from "@local/i10n";
import Image from "@/components/Image";
// import Link from "next/link"
import ContentLoader from "react-content-loader";
import { classNames } from "@local/utils";
import { useMemo } from "react";
import type { Payload } from "@local/payload-client/src/types";
import { getPrice } from "@/lib/getPrice";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import type { ProductCategoryColor } from "@/lib/getCategory";
import Link from "next/link";
import { ITEM_BRAND } from "@/lib/const";
import { trackAction } from "@local/tracking";
interface ItemProps {
  className?: string;
  colors?: Array<ProductCategoryColor>;
  hideSecondImage?: boolean;
  itemId?: string | null;
  categoryName?: string | null;
  product: Pick<Payload.Product, "id" | "images" | "origPrice" | "origPrices" | "prices" | "slug" | "name">;
  testId?: string;
  index: number;
}
export const CategoryListItem = ({
  categoryName,
  className,
  colors,
  hideSecondImage,
  itemId,
  product,
  testId,
  index
}: ItemProps) => {
  const {
    currencyCode
  } = useI10n();
  const productImages = useMemo(() => {
    if (!product) return [];
    const images = [];
    if (product.images && product.images.length > 0) {
      images.push(...product?.images?.filter(image => image.type === "general"), ...product?.images?.filter(image => image.type === "lifestyle"));
    }

    // if (product.regionalImages && product.regionalImages.length > 0) {
    //   const regionalImages = product.regionalImages
    //     .map((image) => image.images)
    //     .flat()
    //     .filter((image) => image?.region?.includes(country))
    //   images.push(...regionalImages)
    // }

    if (product.images && product.images.length > 0) {
      images.push(...product?.images?.filter(image => image.type === "variant"));
    }

    /*
    if (product.bundleitem && product.bundleitem.length > 0) {
      product.bundleitem.forEach((item) => {
        if (item?.product?.images) {
          images.push(
            ...item.product?.images?.filter(
              (image) => image.type === "general"
            ),
            ...item.product?.images?.filter(
              (image) => image.type === "lifestyle"
            ),
            ...item.product?.images?.filter((image) => image.type === "variant")
          )
        }
        if (
          item.product?.regionalImages &&
          item.product?.regionalImages.length > 0
        ) {
          const regionalImages = item.product.regionalImages
            .map((image) => image.images)
            .flat()
            .filter((image) => image?.region?.includes(country))
          images.push(...regionalImages)
        }
      })
    }
    */

    return images;
  }, [product]);
  if (!product) {
    return null;
  }
  const {
    discountPrice,
    fullPrice
  } = getPrice(product, currencyCode);

  // Needed to add this as `at` is not supported in old iOS
  const imageCount = product.images && productImages && productImages.length ? productImages?.length : 0;
  const firstImage = productImages && productImages?.length > 0 ? {
    url: productImages?.[0]?.image.url,
    width: productImages?.[0]?.image.width,
    height: productImages?.[0]?.image.height
  } : null;
  const secondImage = imageCount > 1 && typeof productImages?.[1]?.image !== "string" ? productImages?.[1]?.image : null;
  const trackItem = (product: Pick<Payload.Product, "id" | "images" | "origPrice" | "origPrices" | "prices" | "regionalImages" | "slug" | "bundleitem" | "name">) => {
    trackAction("select_item", {
      item_list_name: product.name,
      items: [{
        item_id: product.id,
        item_name: product.name,
        discount: product.origPrice - product.prices[0].price,
        index,
        item_brand: ITEM_BRAND,
        item_category: categoryName,
        item_list_name: product.name,
        price: product.prices[0].price
      }]
    });
  };
  const COLOR_LIMIT = 7;
  return <div className={classNames("inline-flex flex-col w-64 max-w-full text-center lg:w-auto", className ? className : "")} onClick={() => trackItem(product)} data-sentry-component="CategoryListItem" data-sentry-source-file="Item.tsx">
      <div className="relative group">
        <div className="relative w-full overflow-hidden rounded-lg aspect-square mb-2">
          {!firstImage ? <ContentLoader speed={2} width={211} height={211} viewBox="0 0 211 211" backgroundColor="#f0f0f0" foregroundColor="#dedede" uniqueKey={`related-${product.id}-loader`} className="w-full h-full">
              <rect x="3" y="5" rx="7" ry="7" width="204" height="204" />
            </ContentLoader> : firstImage && secondImage && !hideSecondImage ? <>
              <Image alt={product?.name as string} className="absolute inset-0 object-cover object-center w-full h-full group-hover:opacity-0" height={firstImage?.height as number} src={firstImage?.url as string} width={firstImage?.width as number} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
              <Image alt={product?.name as string} className="object-cover object-center w-full h-full" height={secondImage?.height as number} src={secondImage?.url as string} width={secondImage?.width as number} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
            </> : <Image alt={product?.name as string} className="object-cover object-center w-full h-full group-hover:opacity-75" height={firstImage?.height as number} src={firstImage?.url as string} width={firstImage?.width as number} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />}
        </div>
        {colors && <div className="flex flex-row gap-1 flex-nowrap align-middle justify-center items-center">
            {colors.filter((_, index) => index < COLOR_LIMIT).map((item, index) => {
          const isCamo = item.name.toLowerCase() === "camo";
          return <div key={`product-color-${product.id}-${item.name}-${index}`} aria-hidden="true" className={classNames("min-w-4 mr-1 block h-4 w-4 rounded-full", isCamo ? "" : "border border-black border-opacity-20")} style={{
            backgroundColor: item.color,
            backgroundSize: "cover"
          }}>
                    {isCamo ? <Image src="/images/camo.svg" alt="camo" className="object-cover w-4 h-4 rounded-full" width={16} height={16} /> : ""}
                  </div>;
        })}
            {colors.length > COLOR_LIMIT && <div>+{colors.length - COLOR_LIMIT}</div>}
          </div>}
        <div className="mt-4">
          <h3 className="mt-1 font-semibold text-zinc-900">
            <Link href={`/product/${product?.slug}/${itemId ? `?item_id=${itemId}` : ""}`} prefetch={false} id={testId} data-sentry-element="Link" data-sentry-source-file="Item.tsx">
              <span className="absolute inset-0" />
              {product?.name}
            </Link>
          </h3>

          {fullPrice ? <div className="w-full mt-1">
              <div className={classNames("flex flex-row text-zinc-900", discountPrice !== fullPrice ? "justify-evenly" : "justify-center")}>
                {discountPrice && discountPrice !== fullPrice && <span tabIndex={0} className="line-through opacity-60 xl:mr-1" aria-label="Previous price">
                    <Price value={fullPrice * 100} />
                  </span>}
                {discountPrice ? <span tabIndex={0} className="font-semibold">
                    <Price value={discountPrice * 100} />
                  </span> : <Price value={fullPrice * 100} />}
              </div>
              <div>
                {discountPrice && discountPrice !== fullPrice && <span tabIndex={0} className="w-full text-sm text-center text-red-400">
                    Save&nbsp;
                    {(100 - discountPrice / fullPrice * 100).toFixed(0)}%
                  </span>}
              </div>
            </div> : <p className="mt-1 text-zinc-900">Not available</p>}
        </div>
      </div>
    </div>;
};
export default CategoryListItem;