"use client";

import { trackAction } from "@local/tracking";
import { ITEM_BRAND } from "@/lib/const";
import useRunOnce from "@/utils/useRunOnce";
import type { CategoryTransversalResponse } from "@/lib/getCategory";
interface Props {
  data: CategoryTransversalResponse;
}
export const CategoryListTracking = ({
  data
}: Props) => {
  useRunOnce(() => {
    if (!data.isPromotion) {
      return;
    }
    const items = data.products.map((product, index) => ({
      item_id: product.id,
      item_name: product.name,
      discount: product.origPrice - product.prices[0].price,
      index,
      item_brand: ITEM_BRAND,
      item_category: data.name,
      item_list_name: data.name,
      price: product.prices[0].price
    }));
    trackAction("view_promotion", {
      item_list_name: data.name,
      items
    });
  });
  useRunOnce(() => {
    try {
      const items = data.products.map((product, index) => ({
        item_id: product.id,
        item_name: product.name,
        discount: product.origPrice - (product.prices?.[0]?.price - 0),
        index,
        item_brand: ITEM_BRAND,
        item_category: data.name,
        item_list_name: data.name,
        price: product.prices[0].price
      }));
      trackAction("view_item_list", {
        item_list_name: data.name,
        items
      });
    } catch (error) {
      console.error(JSON.stringify(error));
      console.error("error on tracking");
    }
  }, "view_item_list_v1");
  return null;
};
export default CategoryListTracking;